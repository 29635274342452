import React from 'react';
import './App.css';
import {isMobile} from "react-device-detect";

import Desktop from './Desktop'

export default class App extends React.Component {

  state = {
    deviceType: 'desktop'
  }

  componentDidMount() {
    if (isMobile) {
      this.setState({deviceType: 'mobile'})
    }
  }

  render(){
    return(
      <Desktop deviceType={this.state.deviceType}/>
    )
  }
}
