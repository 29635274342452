import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {isMobile, isIOS} from "react-device-detect";

import HomeScreenDesk from './HomeScreenDesk';
import MissingPosters from './MissingPosters';
import MissingPosterSpec from './MissingPosterSpec';
import MissingPosterSpecMobile from './MissingPosterSpecMobile';

function Desktop(props) {
  return (
    <Router>
      <div className="poster-container">
        <Switch>
          <Route path="/" exact component={() => <HomeScreenDesk deviceType={props.deviceType}/>} />
          <Route path="/posters" exact component={() => <MissingPosters deviceType={props.deviceType}/>} />
          {props.deviceType === "desktop" ?
            <Route path="/posters/:id" component={MissingPosterSpec} />
          :
            <Route path="/posters/:id" component={MissingPosterSpecMobile} />
          }
          <Route
            path="/download"
            exact
            component={() => {
              if (isMobile) {
                if (isIOS) {
                  window.location.href  = "https://apps.apple.com/app/id1479709561";
                  return null;
                } else {
                  window.location.href  = "https://play.google.com/store/apps/details?id=com.retrievr.retrievr";
                  return null;
                }
              } else {
                return (<HomeScreenDesk deviceType={props.deviceType}/>)
              }
            }}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default Desktop;
