import React, { useState, useEffect } from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import { isIOS, isMobile} from "react-device-detect";


function MissingPosterSpec({ match }) {
  useEffect(()=>{
    fetchItem()
  }, [])

  const [item, setItem] = useState({})

  const fetchItem = async() => {
    const data = await fetch(`https://retrievr-api-v2.herokuapp.com/api/v1/posters/${match.params.id}`)
    const posterData = await data.json()
    setItem(posterData)
  }

  const mapsSelector = (lat, lon) => {
    var mapUrl = '?q=' + lat + ',' + lon;
    if (isMobile) {
    var mapUrlFullPath = isIOS ? "maps://" + mapUrl : "geo:" + mapUrl;
    } else {
    window.open(`https://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
    }
    window.open(mapUrlFullPath, '_system');

    // if
    //   ((navigator.platform.indexOf("iPhone") !== -1) ||
    //    (navigator.platform.indexOf("iPod") !== -1) ||
    //    (navigator.platform.indexOf("iPad") !== -1))
    //   window.open(`maps://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
    //
    // else
    //   window.open(`https://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
  }

  return (
    <div className="spec-poster-container" style={{height: window.innerHeight, width: window.innerWidth}}>
      <div className="animal-poster-mobile">
        <div
          style={{height: '100%', width: '95%', marginTop: 20, border: '1px solid #b2bec3', backgroundColor: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: 5, boxShadow: '2px 10px 5px rgba(178, 190, 195,0.6)'}}
          onClick={() => mapsSelector(item.lat, item.long)}
        >
        <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
          <div style={{top: -10, left: 10}}>
            <img src={require('./images/thumbtack.png')} alt="tack" style={{height: 30, width: 40}}/>
          </div>
          <div style={{top: -10, right: 10}}>
            <img src={require('./images/thumbtack.png')} alt="tack" style={{height: 30, width: 40}}/>
          </div>
        </div>
          {item.pet?
            <div style={{marginTop: -35, paddingLeft: 10, paddingRight: 10}}>
              <h1 style={{color: '#00b894', fontSize: 40, fontFamily: 'Comfortaa'}}>Missing {item.pet.species ? item.pet.species : 'Animal'}</h1>
              <h2 style={{color: '#00b894'}}>{item.pet.name}, the {item.pet.breed}</h2>
              <img src={item.pet.imageUrl} alt={item.id} style={{ width: '80%', border: '4px solid #00b894', borderRadius: 300}}/>
              <h3 style={{color: '#00b894', textAlign: 'center'}}>{item.pet_description}</h3>
              <h2 style={{color: '#00b894', marginTop: 25}}>If you have any information, please contact</h2>
              <h2 style={{color: '#00b894', lineHeight: 0.1}}>{item.poster_name}, at ({item.poster_phone.slice(0,3)}) {item.poster_phone.slice(3,6)}-{item.poster_phone.slice(6)}</h2>
              <h4 style={{color: '#00b894', marginTop: 50}}>Click this poster to see where {item.pet.name} was last seen</h4>
            </div>
          :
            null
          }
        </div>
      </div>
      <div style={{width: '100%', backgroundColor: '#dfe6e9', position: 'fixed', bottom: 0, left: 0, right: 0, height: 60, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderTop: '2px solid #636e72'}}>
        <Link to={'/posters'} style={{textDecoration: 'none'}}>
          <h3 style={{color: '#00b894', marginLeft: 10}}>Posters</h3>
        </Link>
        <Link to={'/'} style={{textDecoration: 'none'}}>
          <h3 style={{color: '#00b894', marginRight: 10, fontFamily: 'Comfortaa'}}>Retrievr</h3>
        </Link>
      </div>
    </div>
  );
}

export default MissingPosterSpec;
