import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';


function Desktop(props) {
  return (
    <div className="poster">
      <div className="middle">
        <div className="top-bar">
          <img src="https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/b0/7d/87/b07d8707-36c8-021d-c40f-e8eebccaa811/Icon-83.5@2x.png.png/75x9999bb.png" className="icon-image" alt="icon"/>
          <h1 style={{textAlign: 'center', fontSize: 60, color: '#00b894', marginLeft: 20, fontFamily: 'Comfortaa'}}>Retrievr</h1>
          <div className="icon-image"></div>
        </div>
        <div className="missing-toggle">
          <Link to="/posters" style={{textDecoration: 'none'}}>
            <div className="missing-button">
              <h3 style={{paddingTop: '20%', color: '#00b894', fontSize: 20, textDecoration: 'none', fontWeight: '500'}}>View Missing Pet Posters</h3>
            </div>
          </Link>
        </div>
      </div>
      <div className={props.deviceType === 'desktop' ? "store-div" : "store-div-mobile"}>
        <div>
          <a href="https://apps.apple.com/app/id1479709561"><img src="https://www.hatterasrealty.com/sites/default/files/uploads/apple-app-store-icon.png" height="120" alt="app store"/></a>
        </div>
        <div>
          <a href="https://play.google.com/store/apps/details?id=com.retrievr.retrievr"><img src="https://miro.medium.com/max/5250/1*OIIv4FEjJQMqh-zEPhtlYA.png" height="120" alt="play store"/></a>
        </div>
      </div>
      {props.deviceType === 'desktop' ?
        <div>
          <div className="about-div">
            <div style={{width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
              <h1 style={{fontFamily: 'Comfortaa', fontSize: 34, textAlign: 'left', color: '#00b894'}}>According to the ASPCA... </h1>
              <h3 style={{fontFamily: 'Comfortaa', fontSize: 20, textAlign: 'left', color: '#636e72'}}>"Approximately 6.5 million companion animals enter U.S. animal shelters nationwide every year."</h3>
              <h3 style={{fontFamily: 'Comfortaa', fontSize: 20, textAlign: 'left', color: '#636e72'}}>"Of those, approximately 3.3 million are dogs and 3.2 million are cats."</h3>
              <h3 style={{fontFamily: 'Comfortaa', fontSize: 20, textAlign: 'left', color: '#636e72'}}>Less than 11% of those cats and dogs are ever returned to their owners</h3>
              <a href="https://www.aspca.org/animal-homelessness/shelter-intake-and-surrender/pet-statistics"><h6 style={{fontSize: 12, textAlign: 'left'}}>Source: ASPCA</h6></a>
            </div>
            <div style={{maxWidth: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src={require("./images/shelter-return-pie.png")} alt="iphone icon" style={{height: '100%'}} />
            </div>
          </div>
          <div className="about-div">
            <div style={{maxWidth: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src={require("./images/iphone-icon.png")} alt="iphone icon" style={{height: '100%'}} />
            </div>
            <div style={{width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
              <h1 style={{fontFamily: 'Comfortaa', fontSize: 34, textAlign: 'right', color: '#00b894'}}>Download our mobile application</h1>
              <h3 style={{fontFamily: 'Comfortaa', fontSize: 20, textAlign: 'right', color: '#636e72'}}>Create profiles for your pets</h3>
              <h3 style={{fontFamily: 'Comfortaa', fontSize: 20, textAlign: 'right', color: '#636e72'}}>Join our community of animal advocates</h3>
              <h3 style={{fontFamily: 'Comfortaa', fontSize: 20, textAlign: 'right', color: '#636e72'}}>Help lost pets find their way home</h3>
            </div>
          </div>
          <div className="about-div">
            <div style={{width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
              <h1 style={{fontFamily: 'Comfortaa', fontSize: 34, textAlign: 'left', color: '#00b894'}}>Interact with Virtual missing pet posters</h1>
              <h3 style={{fontFamily: 'Comfortaa', fontSize: 20, textAlign: 'left', color: '#636e72'}}>Create missing posters for your pet the second you know they're missing</h3>
              <h3 style={{fontFamily: 'Comfortaa', fontSize: 20, textAlign: 'left', color: '#636e72'}}>Receive notifications whenever a pet goes missing near you</h3>
              <h3 style={{fontFamily: 'Comfortaa', fontSize: 20, textAlign: 'left', color: '#636e72'}}>View your neighbor's missing pet posters to help them find their companions</h3>
            </div>
            <div style={{maxWidth: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src={require("./images/missing-poster-iphone.png")} alt="iphone icon" style={{height: '100%'}} />
            </div>
          </div>
        </div>
      :
        <div style={{width: '99%', marginTop: 50}}>
          <div>
            <img src={require("./images/iphone-icon.png")} alt="iphone icon" style={{maxHeight: 300, maxWidth: '90%', marginLeft: '-50%'}} />
          </div>
          <div style={{width: '96%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 25}}>
            <h1 style={{fontFamily: 'Comfortaa', fontSize: 24, textAlign: 'right', color: '#00b894'}}>Download our mobile application</h1>
            <h3 style={{fontFamily: 'Comfortaa', fontSize: 16, textAlign: 'right', color: '#636e72'}}>Create profiles for your pets</h3>
            <h3 style={{fontFamily: 'Comfortaa', fontSize: 16, textAlign: 'right', color: '#636e72'}}>Join our community of animal advocates</h3>
            <h3 style={{fontFamily: 'Comfortaa', fontSize: 16, textAlign: 'right', color: '#636e72'}}>Help lost pets find their way home</h3>
          </div>
          <div style={{marginTop: 50}}>
            <img src={require("./images/missing-poster-iphone.png")} alt="iphone icon" style={{maxHeight: 300, maxWidth: '90%', marginRight: '-50%', overflowX: 'hidden'}} />
          </div>
          <div style={{width: '96%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 25, marginLeft: '3%'}}>
            <h1 style={{fontFamily: 'Comfortaa', fontSize: 24, textAlign: 'left', color: '#00b894'}}>Interact with Virtual missing pet posters</h1>
            <h3 style={{fontFamily: 'Comfortaa', fontSize: 16, textAlign: 'left', color: '#636e72'}}>Create missing posters for your pet the second you know they're missing</h3>
            <h3 style={{fontFamily: 'Comfortaa', fontSize: 16, textAlign: 'left', color: '#636e72'}}>Receive notifications whenever a pet goes missing near you</h3>
            <h3 style={{fontFamily: 'Comfortaa', fontSize: 16, textAlign: 'left', color: '#636e72'}}>View your neighbor's missing pet posters to help them find their companions</h3>
          </div>
        </div>
      }
      <div className="bottom-stuff" style={{paddingBottom: props.deviceType === 'desktop' ? 20 : 200}}>
        <a href="mailto:support@retrievrapp.com" >Support</a>
        <a href="https://app.termly.io/document/privacy-policy/27ed6e6b-91ce-4938-bdf5-f6c9ba3361c9" >Privacy Policy</a>
      </div>
    </div>
  );
}

export default Desktop;
