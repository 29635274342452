import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import Geocode from "react-geocode";

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

export default class MissingPosters extends React.Component {

  state = {
    search: "",
    searchCriteria: "",
    radius: 25,
    returnedPosters: [],
    flip: true,
    deviceLat: null,
    deviceLon: null,
    loading: false
  }

  flipPreview = () => {
    this.setState({flip: !this.state.flip})
  }

  locSuccess = (pos) => {
    var crd = pos.coords;
    this.setState({deviceLat: crd.latitude, deviceLon: crd.longitude})
    // console.log('Your current position is:');
    // console.log(`Latitude : ${crd.latitude}`);
    // console.log(`Longitude: ${crd.longitude}`);
    // console.log(`More or less ${crd.accuracy} meters.`);
  }

  locError(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  componentDidMount(){
    this.setState({loading: true})
    fetch('https://retrievr-api-v2.herokuapp.com/api/v1/posters')
    .then(r=>r.json())
    .then(posters => {
      return posters.map(poster => {
        return this.setState({returnedPosters: [...this.state.returnedPosters, poster]})
      })
    })
    navigator.geolocation.getCurrentPosition(this.locSuccess, this.locError, options);
    this.createBlindArray()
  }

  distanceFormula = (lat1, lat2, lon1, lon2) => {
    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p)/2 +
            c(lat1 * p) * c(lat2 * p) *
            (1 - c((lon2 - lon1) * p))/2;

    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  }

  compareDistances = (a, b) => {
    if (this.state.deviceLat) {
  		let distA = this.distanceFormula(
  			this.state.deviceLat,
  			a.lat,
  			this.state.deviceLon,
  			a.long,
  		);
  		let distB = this.distanceFormula(
  			this.state.deviceLat,
  			b.lat,
  			this.state.deviceLon,
  			b.long,
  		);
  		if (distA < distB) {
  			return -1;
  		}
  		if (distA > distB) {
  			return 1;
  		}
  		return 0;
    }
	};

  createBlindArray = () => {
    const blindArray = this.state.returnedPosters.sort(this.compareDistances);
    if (blindArray.length === this.state.returnedPosters.length) {
      this.setState({loading: false})
    }
  }

  render(){
    return (
      <div className={this.props.deviceType === "desktop" ? "missing-poster-container" : "missing-poster-container-mobile"} style={{height: window.innerHeight}}>
        <div className={this.props.deviceType === "desktop" ? "poster-search-container" : "poster-search-container-mobile"}>
          <TextField style={{width: '65%', marginLeft: 5}} id="standard-search" label="Search" type="search" onChange={(e)=>this.setState({search: e.target.value})}/>
          <FormControl style={{width: '20%'}}>
            <InputLabel id="demo-simple-select-outlined-label">
              Criteria
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={this.state.searchCriteria}
              onChange={(e)=>this.setState({searchCriteria: e.target.value})}
              labelWidth={'10%'}
            >
              <MenuItem value={'location'}>Location</MenuItem>
              <MenuItem value={'breed'}>Breed</MenuItem>
              <MenuItem value={'color'}>Color</MenuItem>
            </Select>
          </FormControl>
          <Button>Search</Button>
        </div>
        {!this.state.loading ?
          <div className={this.props.deviceType === "desktop" ? "poster-preview-container" : "poster-preview-container-mobile"}>
          {this.state.returnedPosters.map(poster => {
            return <PosterPreview
              poster={poster}
              key={poster.id}
              flipPreview={this.flipPreview}
              flip={this.state.flip}
              deviceType={this.props.deviceType}
              distanceFormula={this.distanceFormula}
              deviceLat={this.state.deviceLat}
              deviceLon={this.state.deviceLon}
            />
          })}
          <div style={{height: this.props.deviceType === "desktop" ? 70 : 140}} />
        </div>
        :
          <div className={this.props.deviceType === "desktop" ? "poster-preview-container" : "poster-preview-container-mobile"}>
            <div style={{position: 'absolute', top: 0, left: 0, height: '100vh', width: '100vw', backgroundColor: 'rgba(45, 52, 54,0.75)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <CircularProgress style={{color: '#00b894'}} size={120}/>
            </div>
          </div>
        }
        <div style={{width: '100%', backgroundColor: '#dfe6e9', position: 'fixed', bottom: 0, left: 0, right: 0, height: 60, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', borderTop: '2px solid #636e72'}}>
          <Link to={'/'} style={{textDecoration: 'none'}}>
            <h3 style={{color: '#00b894', marginRight: 10, fontFamily: 'Comfortaa'}}>Retrievr</h3>
          </Link>
        </div>
      </div>
    );
  }
}

function PosterPreview(props) {

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_PLACES_API_KEY);

  const distance = props.distanceFormula(props.deviceLat, Number(props.poster.lat), props.deviceLon, Number(props.poster.long)) * 0.621371

  const [hover, setHover] = React.useState(false);
  const [address, setAddress] = React.useState("")

  Geocode.fromLatLng(props.poster.lat, props.poster.long)
    .then(response => {
      const shortFormAddress = `${response.results.length < 11 ? response.results[0].address_components[2].long_name : response.results[0].address_components[3].long_name}, ${response.results.length < 11 ? response.results[0].address_components[4].short_name : response.results[0].address_components[5].short_name}`
      setAddress(shortFormAddress)
    },
    error => {
      console.error(error);
    }
  );

  return(
    <Link to={`/posters/${props.poster.id}`} className={props.deviceType === 'desktop' ? "poster-preview grow" : "poster-preview-mobile"} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
      <h1 style={{textDecoration: 'none', color: '#2d3436'}}>{props.poster.pet.name}</h1>
      <img src={props.poster.pet.imageUrl} alt={props.poster.pet.name} style={{height: '75%', width: '19vw'}}/>
      {hover &&
        <div style={{position: 'absolute', height: '100%', width: '100%', backgroundColor: 'rgba(45, 52, 54,0.9)', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
          <h3 style={{textDecoration: 'none', color: 'white'}}>{address}</h3>
          <h3 style={{textDecoration: 'none', color: '#00b894'}}>Click to see poster details</h3>
        </div>
      }
    </Link>
  )
}
